import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 27 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M25.765 5.816a3.22 3.22 0 0 0-2.263-2.278C21.505 3 13.5 3 13.5 3s-8.005 0-10.002.538a3.22 3.22 0 0 0-2.263 2.278C.7 7.826.7 12.018.7 12.018s0 4.193.535 6.202c.294 1.109 1.162 1.945 2.263 2.242C5.495 21 13.5 21 13.5 21s8.005 0 10.002-.538c1.101-.297 1.969-1.133 2.263-2.242.535-2.01.535-6.202.535-6.202s0-4.192-.535-6.202M10.882 15.825V8.212l6.69 3.806z"
    }, null, -1)
  ])))
}
export default { render: render }